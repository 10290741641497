import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/gl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import ShareIcon from "@material-ui/icons/Share";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import {
  URL_HOME,
  URL_PLAYER,
  URL_LOGIN,
} from "../../../../app/src/Components/Routes/Location";
import AlertNotification from "../../../../app/src/Components/GUI/Alert/Alert";
import Comments from "./Comments";
import Casting from "./Casting";
import Playlist from "./Playlist";
import Favorite from "./Favorite";
import Rating from "./Rating";
import VideoTag from "./VideoTag";
import VideoType from "./VideoType";
import "./VideoDetailPage.css";
import "./ReportContent.css";
import backgroundDegraded from "../../../../app/src/Resources/Images/degradado600.png";
import {
  DETAIL_TYPE_CONTAINER,
  REDIRECT_TO_HOME,
  REDIRECT_TO_LOGIN,
  LOAD_NEXT_CONTENT,
  LOAD_CONTENT_PLAYER,
  REDIRECT_TO_PRODUCTS,
  SHOW_QR_MODAL,
  REDIRECT_TO_STRIPE,
} from "./VideoDetailPageConstants";
import {
  saveContentHashActionCreator,
  saveContentTypeActionCreator,
  saveUserDataActionCreator,
  saveUserURLAvatarActionCreator,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import ReportContentModalComponent from "./ReportContentModalComponent";
import ShareContentModalComponent from "./ShareContentModalComponent";
import ExpiredSessionModalComponent from "../../../../app/src/Components/common/ExpiredSessionModalComponent";
import {
  URL_PRODUCTS,
  URL_VIDEODETAIL,
} from "../../../../app/src/Components/Routes/Location";
import {
  LOCAL_STORAGE_KEY__LOCALE,
  VIDEO_TYPE_LIVE,
  VIDEO_TYPE_LIVE_TIIVII,
  VIDEO_TYPE_TIIVII_LIVE,
  WIDGET_ACTION_DETAIL, WIDGET_TYPE_BANNER,
} from "../../../../app/src/Utils/Constants";
// import QRModalComponent from "../../../../app/src/Components/common/QRModalComponent";
import ErrorMessageModalComponent from "../../../../app/src/Components/common/ErrorMessageModalComponent";
import {
  TENANT,
  TENANT_PETISGO,
  MULTITENANT_CONSTANTS,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import CalendarButtonComponent from "./CalendarButtonComponent";
import {
  getProductsDashboardService,
  getUserDataService,
  logUserAnalyticService,
  getAnonContentsService,
  getRelatedContentsService,
  getSerialContentsService,
  getContentPermissions,
  getContentsService,
  getUserFavorites,
  getCommentsService,
  getFileService,
  getReportTypesService,
  reportContentService,
} from "../../../../app/src/services/services";
import {
  DETAIL_VALUE,
  PAGE_ID_KEY,
  PAGE_VIEW_ACTION,
  SELECT_ITEM_ACTION,
} from "../../../../app/src/analyticsConstants/analyticsConstants";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {
  DEFAULT_COMMENT_LIMIT,
  DEFAULT_COMMENT_SORT,
  DEFAULT_PAGE,
} from "./CommentsConstants";
import i18n from "../../../../app/src/i18n";
import {URL_BASE, URL_BASE_IMAGE, URL_BASE_V2} from "../../../../app/src/services/servicesConstants";
import {getToken, getWidgetDescription, getWidgetTitle} from "../../../../app/src/Utils/utils";
import {USER_ANONYMOUS} from "../../../../home/src/Pages/Home/HomeComponentConstants";
import { isCordovaAvailable } from "../../../../app/src/Utils/secureStorageUtil";

const VideoDetailPage = ({ scrollRef }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  let { id } = useParams();
  const { keycloak, initialized } = useKeycloak();
  const userToken = getToken(keycloak);
  const username = keycloak?.tokenParsed?.preferred_username;

  const userInformation = useSelector((state) => state.commonState.userData);
  const tenantRules = useSelector((state) => state.commonState.rules);
  const userAvatarURL = useSelector((state) => state.commonState.userURLAvatar);
  const query = new URLSearchParams(window.location.search);
  const payload = query.get("payload") || false;

  const titleColor = document
    .getElementById("root")
    .style.getPropertyValue("--rowTitleColor");

  const [content, setContent] = useState(null);
  const [serialContents, setSerialContens] = useState([]);
  const [totalComments, setTotalComments] = useState(null);
  const [userAvatar, setUserAvatar] = useState(null);
  const [userData, setUserData] = useState(null);
  const [controller, setController] = useState(null);
  const [displayOptions, setDisplayOptions] = useState(null);
  const [sentMessage, setSentMessage] = useState(false);
  const [reason, setReason] = useState(null);
  const [reasonDescription, setReasonDescription] = useState(undefined);
  const [reportSuccess, setReportSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [shareContent, setShareContent] = useState(null);
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false);
  const [displayQRModal, setDisplayQRModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [calendarButton, setCalendarButton] = useState(null);
  const [accessBehavior, setAccessBehavior] = useState(null);
  const [urlToRedirect, setUrlToRedirect] = useState(null);
  const [checkoutURL, setCheckoutURL] = useState(null);
  const [allowed, setAllowed] = useState(undefined);
  const [widgetType, setWidgetType] = useState(null);

  const [textMessageErrorBody, setTextMessageErrorBody] = useState(
    "error.modal.rating.message"
  );
   const isLoginRequired = MULTITENANT_CONSTANTS[TENANT].showLoginButton;

  const refOptions = useRef(null);
  const refShare = useRef(null);

  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);

  const version = MULTITENANT_CONSTANTS[TENANT].contentDetailsVersion
    ? MULTITENANT_CONSTANTS[TENANT].contentDetailsVersion
    : 1;

  const languageSelected =
    useSelector((state) => state.commonState.languageSelected) || i18n.language;

  const locale = localStorage.getItem(LOCAL_STORAGE_KEY__LOCALE) || i18n.language;

  const loadTenantLocale = () => {
    moment.locale(tenantStyles.momentLocale);
  };

  const getCommentsCallback = (response) => {
    if (response.comments.length > 0) {
      setContent((prev) => ({ ...prev, comments: response.comments }));
      setTotalComments(response.meta.filter_count);
    }
  };

  const getFileCallback = (response) => {
    setUserAvatar(response.data.data.url);
    dispatch(saveUserURLAvatarActionCreator(response.data.data.url));
  };

  useEffect(() => {
    if (userAvatarURL) {
      setUserAvatar(userAvatarURL);
    } else {
      if (!!userData?.avatar) {
        getFileService(
          MULTITENANT_CONSTANTS[TENANT].tenantServer,
          userData.avatar,
          userToken,
          getFileCallback
        );
      }
    }
  }, [userAvatarURL]);

  useEffect(() => {
    getCommentsService(
      TENANT,
      id,
      DEFAULT_COMMENT_LIMIT,
      DEFAULT_PAGE,
      DEFAULT_COMMENT_SORT,
      getCommentsCallback
    );
    loadTenantLocale();
  }, [content?.content]);

  useEffect(() => {
    !!username && logUserAnalyticService(
      TENANT,
      username,
      PAGE_VIEW_ACTION,
      PAGE_ID_KEY,
      DETAIL_VALUE,
      userToken
    );
  }, [userData]);

  const handleShareContent = () => {
    setShareContent(!shareContent);
  };

  const hideShareModal = () => {
    setShareContent(false);
  };

  const toggleOptions = () => {
    setDisplayOptions(!displayOptions);
  };

  const reportContentServiceCallback = () => {
    setReportSuccess(true);
    setSentMessage(true);
  };

  useEffect(() => {
    if (reportSuccess === true) {
      setTimeout(() => {
        setReportSuccess(false);
      }, 3000);
    }
  }, [reportSuccess]);

  const handleReportContent = () => {
    reportContentService(TENANT, id, reasonDescription, userToken, reportContentServiceCallback);
    setReason(null);
  };

  const handleCloseModal = () => {
    setReasonDescription(undefined);
    setSentMessage(false);
    setDisplayOptions(false);
  };

  const handleSetReason = (value) => {
    setReason(value);
  };

  const handleSetReasonDescription = (event) => {
    setReasonDescription(event.target.value);
    handleSetReason(!!event.target.value);
  };

  const hideOptions = () => {
    setReason(null);
    setDisplayOptions(false);
  };

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const getRelatedServiceCallback = (response) => {
    setContent((prev) => ({ ...prev, relatedContent: response["contents-related"]?.data, serialContent: response["contents-items"]?.data }));
    setWidgetType(response["contents-related"]?.widgetType ?? WIDGET_TYPE_BANNER);
  };

  const sortContents = (contents) => {
    const orderedContents = contents.sort((a, b) =>
      a.episode > b.episode ? 1 : b.episode > a.episode ? -1 : 0
    );

    return orderedContents;
  };

  useEffect(() => {
    if (content?.content?.type !== "container" && serialContents?.length > 0) {
      const nextContentId = parseInt(id, 10) + 1;
      const contentFiltered = serialContents.find(
        (item) => item.id === nextContentId
      );
      if (contentFiltered) {
        setContent((prev) => ({ ...prev, nextContent: contentFiltered }));
      }
    }
  }, [serialContents, id, content?.content?.type]);

  const getSerialContentsCallback = (response, allowed) => {
    if (response.data[0].type !== "container") {
      setSerialContens(response.data);
      setAccessBehavior(
        allowed
          ? LOAD_NEXT_CONTENT
          : REDIRECT_TO_LOGIN
      );
    } else {
      setAccessBehavior(null);
    }
    const serialContents = sortContents(response.data);
  //  setContent((prev) => ({ ...prev, serialContent: serialContents }));
  };

  const getWatchNextCallback = (response) => {
    const orderedContents = sortContents(response.data);
    setSerialContens([...orderedContents]);
  };

  useEffect(() => {
    if (
      serialContents?.length === 0 &&
      content?.content?.parent?.type === "container"
    ) {
      getSerialContentsService(
        TENANT,
        content?.content?.parent.id,
          undefined,
        getWatchNextCallback
      );
    }
  }, [serialContents, content]);

  const contentPermissionsCallback = (response, contentType) => {
    const allowedResponse = response?.allowed;
    if (allowedResponse !== undefined) {
        setAllowed(allowedResponse);
      if (contentType === "container") {
        getSerialContentsService(TENANT, id, allowedResponse, getSerialContentsCallback);
      } else {
        allowedResponse ? setAccessBehavior(LOAD_CONTENT_PLAYER) : setAccessBehavior(REDIRECT_TO_PRODUCTS);
      }
    } else {
      setAccessBehavior(REDIRECT_TO_LOGIN);
    }
    turnOffLoading();
  };

  const getUserFavoritesCallback = (response) => {
    const favoriteContents = response.data;
    let isFavorite = false;

    favoriteContents?.forEach((item) => {
      if (item.id === parseInt(id, 10)) {
        isFavorite = true;
      }
    });

    setContent((prev) => ({ ...prev, isFavorite: isFavorite }));
  };

  const  scrollMoving = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  const getContentsCallback = (response) => {
    const responseContent = response.data;
    const contentType = responseContent.type;
    dispatch(saveContentTypeActionCreator(contentType));
    setContent({
      content: responseContent,
      isFree: false,
      canRate: true,
      canFav: true,
      canReport: true,
    });
    if (!!id && isLoginRequired) {
      getContentPermissions(
          TENANT,
          id,
          userToken,
          contentType,
          contentPermissionsCallback
      );
    }
    getRelatedContentsService(TENANT, id, locale, getRelatedServiceCallback);
    getUserFavorites(TENANT, username, userToken, getUserFavoritesCallback);
    scrollMoving();
  };

  const anonContentPermissionsCallback = (response, contentType) => {
    const allowed = !!response.allowed;
    if (contentType === "container") {
      getSerialContentsService(TENANT, id, allowed, getSerialContentsCallback);
    } else {
          dispatch(saveContentHashActionCreator(response.hash));
          setAccessBehavior(!allowed ? REDIRECT_TO_LOGIN
              : LOAD_CONTENT_PLAYER
          );
      }
    turnOffLoading();
  };

  const getAnonContentsCallback = (response) => {
    const responseContent = response?.data;
    const contentType = responseContent?.type;
    dispatch(saveContentTypeActionCreator(contentType));
    setContent({
      content: responseContent,
      isFree: false,
      canRate: false,
      canFav: false,
    });
    if(!!id) {
      getContentPermissions(
          TENANT,
          id,
          undefined,
          contentType,
          anonContentPermissionsCallback
      );
    }
    getRelatedContentsService(
      TENANT,
      id,
      locale,
      getRelatedServiceCallback
    );
    scrollMoving();
  };

  useEffect(() => {
    if (!!username) {
      getContentsService(TENANT, id, userToken, payload, getContentsCallback);
    } else {
      getAnonContentsService(TENANT, id, payload, getAnonContentsCallback);
    }
  }, [id, languageSelected]);


  const productCheckoutCallback = (data) => {
    setCheckoutURL(data.url);
  };

  useEffect(() => {
    if (!!keycloak.authenticated && initialized) {
        const body = { returnUrl: `${window.location.origin}/user` };
        getProductsDashboardService(
          MULTITENANT_CONSTANTS[TENANT].tenantServer,
          username,
          locale,
          userToken,
          body,
          productCheckoutCallback
        );
      }
  }, []);

  const getReportTypesCallback = (response) => {
    const reportTypes = response.data;
    const updatedContent = { ...content, availableReportReasons: reportTypes };
    setContent(updatedContent);
  };

  useEffect(() => {
    //getReportTypesService(TENANT, getReportTypesCallback);
  }, []);

  const getUserDataCallback = (response) => {
    dispatch(saveUserDataActionCreator(response));
    setUserData(response);
  };

  useEffect(() => {
    if (isLoginRequired && !!userInformation?.username) {
      setUserData(userInformation);
    } else if (isLoginRequired && !!username) {
      getUserDataService(
        MULTITENANT_CONSTANTS[TENANT].tenantServer,
        username,
        keycloak.token,
        getUserDataCallback
      );
    }
  }, [userInformation]);

  if (!id) {
    return <Redirect to={URL_HOME} />;
  }

  const selectedVideo = (id) => {
    const username = userData?.username ? userData?.username : USER_ANONYMOUS;
    !!username && logUserAnalyticService(
      TENANT,
      username,
      SELECT_ITEM_ACTION,
      undefined,
      undefined,
      userToken,
      id
    );
  };

  const handleCloseSessionModal = () => {
    setSessionExpiredModal(false);
  };

  const handleGoToPlayer = (array) => {
    const filteredArray = array.filter(
      (item) => item.type !== DETAIL_TYPE_CONTAINER
    );
    history.push(URL_PLAYER + "/" + filteredArray[0].id);
  };

  const handleQRModal = () => {
    setDisplayQRModal((displayQRModal) => !displayQRModal);
  };

  const handleGoToLogin = () => {
    const currentUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;
    localStorage.setItem("redirectAfterLogin", currentUrl);
    setMessageModal(true);
    setTextMessageErrorBody("error.modal.rating.message");
    setUrlToRedirect(URL_LOGIN);
  };

  const handleGoToHome = () => {
    setUrlToRedirect(URL_HOME);
    setMessageModal(true);
    setTextMessageErrorBody("error.modal.common.title");
  };

  const handleGoToSeeContent = () => {
    history.push(URL_PLAYER + "/" + content?.content?.id);
  };

  const handleGoToSeeNextContent = () => {
    turnOnLoading();
    history.push(URL_VIDEODETAIL + "/" + content?.nextContent?.id);
  };

  const groupButtonsContent = () => {
    if (!MULTITENANT_CONSTANTS[TENANT].showViewButton) {
      if (content?.content?.type === DETAIL_TYPE_CONTAINER.toLowerCase()) {
        return null;
      }
    }

    return accessBehavior === LOAD_CONTENT_PLAYER ? (
      <div className="boton-play">
        <button
          className={`BotonVer ${version === 2 && "BotonVerV2"}`}
          onClick={handleGoToSeeContent}
        >
          <PlayCircleOutlineIcon />
          {t("ficha.vercontido")}
        </button>
      </div>
    ) : isLoginRequired && accessBehavior === REDIRECT_TO_LOGIN ? (
      <div className="boton-play">
        <button
          className={`BotonVer ${version === 2 && "BotonVerV2"}`}
          onClick={handleGoToLogin}
        >
          <PlayCircleOutlineIcon />
          {t("ficha.vercontido")}
        </button>
      </div>
    ) : accessBehavior === REDIRECT_TO_HOME ? (
      <div className="boton-play">
        <button
          className={`BotonVer ${version === 2 && "BotonVerV2"}`}
          onClick={handleGoToHome}
        >
          <PlayCircleOutlineIcon />
          {t("ficha.vercontido")}
        </button>
      </div>
    ) : accessBehavior === REDIRECT_TO_PRODUCTS ? (
      <div className="boton-play">
        <button
          className={`BotonVer ${version === 2 && "BotonVerV2"}`}
          onClick={() => window.open(URL_PRODUCTS + "/" + id)}
        >
          {t("products.subscribe")}
        </button>
      </div>
    ) : accessBehavior === LOAD_NEXT_CONTENT &&
      content?.serialContent?.length > 0 ? (
      <div className="boton-play">
        <button
          className={`BotonVer ${version === 2 && "BotonVerV2"}`}
          onClick={() => handleGoToPlayer(content.serialContent)}
        >
          <PlayCircleOutlineIcon />
          {t("ficha.comezarver")}
        </button>
      </div>
    ) : accessBehavior === SHOW_QR_MODAL ? (
      <div className="boton-play">
        <button
          className={`BotonVer ${version === 2 && "BotonVerV2"}`}
          onClick={handleQRModal}
        >
          <PlayCircleOutlineIcon />
          {t("ficha.comezarver")}
        </button>
      </div>
    ) : accessBehavior === REDIRECT_TO_STRIPE ? (
      <div className="boton-play">
        <button
          className={`BotonVer ${version === 2 && "BotonVerV2"} ${
            !checkoutURL && "buttonDisabled "
          }`}
          disabled={!checkoutURL}
          onClick={!!checkoutURL && (() => window.open(checkoutURL))}
        >
          {t("products.subscribe")}
        </button>
      </div>
    ) : (
      <></>
    );
  };

  const extraContent = (content) => {
    return (
      <>
        {(content?.isFree || !content?.isFree) && (
          <div className={"textBlock"}>
            <div className={"textTitle alignLeft"}>
              {t("ficha.tipocontenido")}
            </div>
            {content?.isFree ? (
              <div className={"textInfo"}>
                {t("ficha.opciondecontenidogratis")}
              </div>
            ) : (
              <div className={"textInfo"}>
                {t("ficha.opciondecontenidopago")}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const renderCalendarButton = () => {
    const contentTypeCondition =
      content?.content?.type === VIDEO_TYPE_LIVE ||
      content?.content?.type === VIDEO_TYPE_LIVE_TIIVII ||
      content?.content?.type.toLowerCase() === VIDEO_TYPE_TIIVII_LIVE.toLowerCase();
    const contentDate = new Date(content?.content?.starttime || content?.content?.start * 1000);
    const now = new Date();

    const dateCondition = contentDate > now;
    setCalendarButton(!!MULTITENANT_CONSTANTS[TENANT]?.showCalendarButton && !!contentTypeCondition && !!dateCondition);
  };

  useEffect(() => {
    if (!!content) {
      renderCalendarButton();
    }
  }, [content]);

  const detailTitleAndAuthor = () => {
    return (
        <div className={`FichaTitle ${version === 2 && "FichaTitleV2"}`}>
          {getWidgetTitle(content?.content)}
          {MULTITENANT_CONSTANTS[TENANT].showAuthor && (
              <span
                  className={"ContentAuthor"}> {t('reportcontent.author')} {content?.content?.author}
            </span>)}
        </div>
    );
  };

  const detailCategory = () => {
    return (
      content?.content?.category &&
      MULTITENANT_CONSTANTS[TENANT].showCategory && (
        <span className="Xenero">{content?.content?.category?.code}</span>
      )
    );
  };

  const detailPublishDate = () => {
    return (
      content?.content?.publishDate &&
      MULTITENANT_CONSTANTS[TENANT].showPublishDate && (
        <span className="Data">
          {moment(content?.content.publishDate, "YYYY-MM-DD").format("ll")}
        </span>
      )
    );
  };

  const detailSeasons = () => {
    return (
        content?.content?.seasons && (
        <span className="Temporadas">
          {t("ficha.seasons", { seasons: content?.content.seasons })}
        </span>
      )
    );
  };

  const detailRating = () => {
    return (
      content?.content?.rating &&
      MULTITENANT_CONSTANTS[TENANT].showRating && (
        <span className="Idade">
          {t("ficha.rating", { rating: content?.content?.rating?.code })}
        </span>
      )
    );
  };

  const detailBackToSeason = () => {
    return (
      !!content?.content?.parent &&
      MULTITENANT_CONSTANTS[TENANT].showBackToSeason && (
        <span className="button-back">
          <Link
            to={URL_VIDEODETAIL + "/" + content?.content?.parent?.id}
            className=""
          >
           {MULTITENANT_CONSTANTS[TENANT].seasonClassification ?
               t('ficha.backToSeason')  :
               t('ficha.back')
           }
          </Link>
        </span>
      )
    );
  };

  const detailWatchNext = () => {
    return (
      accessBehavior === LOAD_CONTENT_PLAYER &&
      !!content?.content?.parent?.id &&
      content?.nextContent?.parent.id === content?.content?.parent?.id && (
          <div className="boton-play">
          <button
          className={`BotonVer ${version === 2 && "BotonVerV2"}`}
          onClick={handleGoToSeeNextContent}
        >
          {t("ficha.watchNext")}
        </button>
          </div>
      )
    );
  };

  const detailFavorite = () => {
    return (
      content?.canFav &&
      content.content.type !== "container" && (
        <Favorite
          isFavorite={content?.isFavorite}
          id={id}
          username={username}
          largeVersion={version === 2}
          version={version}
          token={userToken}
        />
      )
    );
  };

  const detailShare = () => {
    return (
      <div className="Share" onClick={() => handleShareContent()}>
        <ShareIcon />
      </div>
    );
  };

  const detailCalendar = () => {
    return calendarButton && <CalendarButtonComponent detail={content} />;
  };

  const detailTags = () => {
    return (
      MULTITENANT_CONSTANTS[TENANT].showVideoTags &&
      content?.content?.tags?.length > 0 && (
        <div
          className={`VotesCategoriesContainer ${
            version === 2 && "VotesCategoriesContainerV2 CategoriesV2"
          }`}
        >
          <VideoType subtitle={content?.content} />
          {content?.content?.tags?.map((t, index) => (
            <VideoTag tag={t} key={t + "-" + index} />
          ))}
        </div>
      )
    );
  };

  const detailScore = () => {
    return (
      MULTITENANT_CONSTANTS[TENANT].showScore && (
        <div
          className={`VotesCategoriesContainer ${
            version === 2 && "VotesCategoriesContainerV2"
          }`}
        >
          <Rating
            videoDetailId={content?.content.id}
            username={userData?.username}
            editRating={content?.canRate}
            totalRating={content?.content?.valoration}
            votes={content?.content?.votes}
            id={id}
          />
        </div>
      )
    );
  };

  const detailDescriptionMisc = () => {
    return (
      MULTITENANT_CONSTANTS[TENANT].showVideoDetails && (
        <div
          className={`textDetailContainer ${
            version === 2 && "textDetailContainerV2"
          }`}
        >
          {MULTITENANT_CONSTANTS[TENANT].showAuthor &&
            content?.content?.author !== null && (
              <div className={"textBlock"}>
                <div className={"textTitle alignLeft"}>{t("ficha.autor")}</div>
                <div className={"textInfo"}>{content?.content?.author}</div>
              </div>
            )}
          {content?.content?.category !== null && (
            <div className={"textBlock"}>
              <div className={"textTitle alignLeft"}>
                {t("ficha.categoria")}
              </div>
              <div className={"textInfo"}>
                {content?.content?.category?.code}
              </div>
            </div>
          )}
          {content?.content?.subcategory !== null && (
            <div className={"textBlock"}>
              <div className={"textTitle alignLeft"}>
                {t("ficha.subcategoria")}
              </div>
              <div className={"textInfo"}>
                {content?.content?.subcategory?.code}
              </div>
            </div>
          )}
          {content?.content?.publishDate !== null && (
            <div className={"textBlock"}>
              <div className={"textTitle alignLeft"}>{t("ficha.estreno")}</div>
              <div className={"textInfo"}>
                {moment(content?.content?.publishDate).format("DD-MM-YYYY")}
              </div>
            </div>
          )}
          {TENANT !== TENANT_PETISGO && extraContent(content)}
        </div>
      )
    );
  };

  const detailReport = () => {
    return (
      content?.canReport &&
      MULTITENANT_CONSTANTS[TENANT].showReportContentOption &&
      content?.content?.type !== "container" && (
        <div
          className={`"ReportContentContainer" ${
            version === 2 && "ReportContentContainerV2"
          }`}
        >
          <div className="ReportContent">
            <div
              className="ReportContentButton"
              onClick={() => toggleOptions()}
            >
              <span className="ReportIcon">
                {t("reportcontent.reportbuttonicon")}
              </span>
              <span className="ReportText">
                {t("reportcontent.reportbutton")}
              </span>
            </div>
          </div>
        </div>
      )
    );
  };

  const detailPlayFav = () => {
    return (
      <div
        className={`PlayFavContainer ${version === 2 && "PlayFavContainerV2"}`}
      >
        {groupButtonsContent()}
        {detailWatchNext()}
        {!!username && detailFavorite()}
        {version !== 2 && detailShare()}
        {detailCalendar()}
      </div>
    );
  };

  const detailDescriptionTitle = () => {
    return (
      <p
        className={`DescriptionTitle ${version === 2 && "DescriptionTitleV2"}`}
      >
        {t("detail.description")}
      </p>
    );
  };

  const detailDescriptionDetail = () => {
    return (
      <div
        className={`DescriptionDetail ${
          version === 2 && "DescriptionDetailV2"
        }`}
      >
        {getWidgetDescription(content.content)}
      </div>
    );
  };

  const detailViewV2 = () => {
    return (
      <>
        <div className={"contentPrincipalImage"}></div>
        <div className="FichaOverContainer FichaOverContainerV2">
          <div className="detailsContainer">
            <div className="leftSide">
              {detailTitleAndAuthor()}
              <div className="InfoPrevia InfoPreviaV2">
                {detailCategory()}
                {detailPublishDate()}
                {MULTITENANT_CONSTANTS[TENANT].playMarathon || (!MULTITENANT_CONSTANTS[TENANT].playMarathon && !!content?.content?.parentId) && detailSeasons()}
                {detailRating()}
                {detailBackToSeason()}
              </div>
              {detailPlayFav()}
            </div>
            <div className="rightSide">
              {detailScore()}
              {detailTags()}
              {detailReport()}
            </div>
          </div>
        </div>
      </>
    );
  };

  const detailViewV1 = () => {
    return (
      <div className={"contentPrincipalImage"}>
        <div
          className="FichaOverContainer"
          style={{ backgroundImage: "url(" + backgroundDegraded + ")" }}
        >
          {detailTitleAndAuthor()}
          {detailScore()}
          {detailTags()}
          {detailPlayFav()}
          <div className={`InfoPrevia InfoPreviaV2`}>
            {detailCategory()}
            {detailPublishDate()}
            {MULTITENANT_CONSTANTS[TENANT].playMarathon || (!MULTITENANT_CONSTANTS[TENANT].playMarathon && !!content?.content?.parentId) && detailSeasons()}
            {detailRating()}
            {detailBackToSeason()}
          </div>
          {detailReport()}
        </div>
      </div>
    );
  };

  const descriptionViewV2 = () => {
    return (
      <Col>
        <Row>{detailDescriptionTitle()}</Row>
        <Row>{detailDescriptionDetail()}</Row>
        <Row>{detailDescriptionMisc()}</Row>
      </Col>
    );
  };

  const descriptionViewV1 = () => {
    return (
      <Row>
        <Col md={12} lg={6}>
          {detailDescriptionTitle()}
          {detailDescriptionDetail()}
        </Col>
        <Col md={12} lg={5}>
          {detailDescriptionMisc()}
        </Col>
      </Row>
    );
  };

  return (
    <div className="VideoDetailPage" id="detailContainer">
      {error && <AlertNotification />}
      {reportSuccess && (
        <AlertNotification
          type="success"
          showtitle={false}
          text={t("reportcontent.success")}
        />
      )}
      {content !== null && content?.content && (
        <>

          <div className={"principalImage"}>
            {!!content?.content?.background?.data?.asset_url &&
            <img
              src={`${URL_BASE_IMAGE}${content?.content?.background?.data?.asset_url}`}
              alt={"Cover image"}
              onClick={accessBehavior === REDIRECT_TO_LOGIN ? handleGoToLogin : handleGoToSeeContent}
            />}
            {version === 2 ? detailViewV2() : detailViewV1()}
          </div>
          {content?.serialContent && (
            <div className={"playlistContainer serialContent"}>
              <Playlist
                clickCallback={selectedVideo}
                playlist={content?.serialContent}
                typeBanner="Banner"
                detail={true}
                trigger={WIDGET_ACTION_DETAIL}
                className="FrameworkHome"
                turnOffLoading={turnOffLoading}
                turnOnLoading={turnOnLoading}
                titleColor={titleColor}
                contentId={content?.content.id}
              />
            </div>
          )}
          <div className={"contentContainer"}>
            {version === 2 ? descriptionViewV2() : descriptionViewV1()}
            {content?.content?.participants.length > 0 && (
              <Casting
                className="FichaCasting"
                participants={content?.content?.participants}
              />
            )}
            {isLoginRequired && MULTITENANT_CONSTANTS[TENANT].showComments &&
                content?.comments && (
            <div className="CommentsContainer">
                  <Comments
                    comments={content?.comments}
                    videoDetailId={content?.content.id}
                    userName={username}
                    totalComments={totalComments}
                    titulo={t("ficha.doacomment")}
                    placeholder={t("ficha.commentplaceholder")}
                    avatar={userAvatar}
                    token={userToken}
                  />
            </div>
                )}
          </div>

          <div className="FichaInfoRelated">
            {content?.relatedContent && (
              <Playlist
                title={t("ficha.explora")}
                clickCallback={selectedVideo}
                hideSubtitle={true}
                playlist={content?.relatedContent}
                typeBanner={widgetType}
                detail={true}
                className="FrameworkHome"
                turnOffLoading={turnOffLoading}
                turnOnLoading={turnOnLoading}
                trigger={WIDGET_ACTION_DETAIL}
                titleColor={titleColor}
                contentId={content?.content.id}
              />
            )}
          </div>
          {displayOptions && (
            <ReportContentModalComponent
              refOptions={refOptions}
              availableReportReasons={content?.availableReportReasons}
              hideOptions={hideOptions}
              reason={reason}
              handleSetReason={handleSetReason}
              reasonDescription={reasonDescription}
              handleSetReasonDescription={handleSetReasonDescription}
              handleReportContent={handleReportContent}
              handleCloseModal={handleCloseModal}
              sentMessage={sentMessage}
            />
          )}
          {shareContent && (
            <ShareContentModalComponent
              hideShareModal={hideShareModal}
              refShare={refShare}
              title={content?.content?.title}
              description={content?.content?.description}
            />
          )}
        </>
      )}
      {isLoginRequired && messageModal && (
        <ErrorMessageModalComponent
          textTitle="error.modal.common.title"
          textBody={textMessageErrorBody}
          textButton="user.login"
          url={urlToRedirect}
          onClose={() => { setMessageModal(false); }}
        />
      )}
      {isLoginRequired && sessionExpiredModal && (
        <ExpiredSessionModalComponent
          handleCloseModal={handleCloseSessionModal}
        />
      )}
      {/* Quizas se use en un futuro */}
      {/*displayQRModal && (
        <QRModalComponent
          url={''}
          goBack={false}
          handleCloseQRModal={handleQRModal}
        />
      )*/}
    </div>
  );
};

export default WithAuthorization(VideoDetailPage);
